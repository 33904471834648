var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TextInput } from 'ionic-angular';
import { AutoCompleteComponent } from '@apto/autocomplete';
import { CallListModel } from '@apto/models';
import { CallListsService, ContactsService } from '@apto/services';
import { PaginationNavigation } from '@apto/shared/components/pagination-navigation/pagination-navigation';
import './edit-call-list-form.scss';
var EditCallListForm = /** @class */ /*@__PURE__*/ (function () {
    function EditCallListForm(contactsService, callListsService, formBuilder) {
        this.contactsService = contactsService;
        this.callListsService = callListsService;
        this.formBuilder = formBuilder;
        this.addedContacts = [];
        this.callList = new CallListModel({ configJson: {}, type: CallListModel.TYPE.CONTACT_GROUP_LIST });
        this.onPaginationChange = new EventEmitter();
        this.CallListModel = CallListModel; // tslint:disable-line
        this.isSaving = false;
        this.listCounts = {};
        this.removedMembers = [];
        this.useDefaultActivityName = true;
    }
    EditCallListForm.prototype.ngOnInit = function () {
        this.setupForm();
    };
    EditCallListForm.prototype.ngOnChanges = function (changes) {
        if (changes.callList &&
            changes.callList.previousValue &&
            changes.callList.previousValue._id !== changes.callList.currentValue._id) {
            this.setupForm();
        }
    };
    EditCallListForm.prototype.setupForm = function () {
        this.listCounts = this.getContactListCounts();
        this.members = [].concat(this.callList.members);
        this.originalMemberList = [].concat(this.callList.members);
        this.members = this.sortMembers(this.members);
        this.form = this.formBuilder.group({
            name: [
                { value: this.callList.name, disabled: this.callList.isCoreClientsList },
                [Validators.required],
            ],
            description: [this.callList.description, Validators.maxLength(255)],
            frequency: [(this.callList.configJson.daysSinceContact || '').toString(), Validators.required]
        });
    };
    EditCallListForm.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var cl;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.callList.name !== this.form.controls.name.value && this.form.controls.name.value.length > 0))
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, this.callListsService.fetch({
                                where: { name: this.form.controls.name.value },
                                slim: true,
                                immediate: true,
                            })];
                    case 1:
                        cl = _a.sent();
                        if (cl && cl.length > 0) {
                            this.form.controls.name.setErrors({ duplicatedName: true });
                            return [2 /*return*/];
                        }
                        _a.label = 2;
                    case 2:
                        if (this.form.valid) {
                            this.callList.name = this.form.controls.name.value;
                            this.callList.description = this.form.controls.description.value;
                            this.callList.type = this.callList.type || CallListModel.TYPE.CONTACT_GROUP_LIST;
                            this.callList.configJson.daysSinceContact = this.form.controls.frequency.value;
                            return [2 /*return*/, {
                                    addedContacts: this.addedContacts,
                                    callList: this.callList,
                                    removedMembers: this.removedMembers
                                }];
                        }
                        else {
                            Object.keys(this.form.controls).forEach(function (fieldKey) {
                                _this.form.controls[fieldKey].markAsDirty();
                            });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    EditCallListForm.prototype.getContactListCounts = function () {
        var counts = {};
        this.callLists.forEach(function (callList) {
            callList.members.forEach(function (member) {
                if (!counts[member.contactId]) {
                    counts[member.contactId] = 0;
                }
                counts[member.contactId]++;
            });
        });
        return counts;
    };
    EditCallListForm.prototype.addContact = function (contact) {
        var inMembers = this.originalMemberList.findIndex(function (member) { return member.contactId === contact._id; }) !== -1;
        var inAdded = this.addedContacts.findIndex(function (el) { return el._id === contact._id; }) !== -1;
        if (!inAdded && !inMembers) {
            this.addedContacts.push(contact);
        }
        this.autocomplete.setValue('');
        this.addedContacts = this.sortMembers(this.addedContacts);
    };
    EditCallListForm.prototype.removeAddedContact = function (index) {
        this.addedContacts.splice(index, 1);
    };
    EditCallListForm.prototype.undoAddedContacts = function () {
        this.addedContacts = [];
    };
    EditCallListForm.prototype.removeContact = function (index) {
        var removed = this.members.splice(index, 1);
        this.removedMembers.push(removed[0]);
    };
    EditCallListForm.prototype.undoRemovedItems = function () {
        this.members = [].concat(this.originalMemberList);
        this.removedMembers = [];
        this.members = this.sortMembers(this.members);
    };
    EditCallListForm.prototype.sortMembers = function (members) {
        function compare(a, b) {
            var aContact = a.contact || a;
            var bContact = b.contact || b;
            if (aContact.fullName.toLowerCase() < bContact.fullName.toLowerCase()) {
                return -1;
            }
            if (aContact.fullName.toLowerCase() > bContact.fullName.toLowerCase()) {
                return 1;
            }
            return 0;
        }
        return members.sort(compare);
    };
    EditCallListForm.prototype.disableEnterKey = function (e) {
        if (e.key === 'Enter') {
            e.stopPropagation();
            e.preventDefault();
        }
    };
    return EditCallListForm;
}());
export { EditCallListForm };
