
      <ion-input
              #inputElem
              (keyup)="getItems()"
              (tap)="showResultsFirst && getItems()"
              [(ngModel)]="keyword"
              [placeholder]="options.placeholder == null ? defaultOpts.placeholder : options.placeholder"
              [type]="options.type == null ? defaultOpts.type : options.type"
              [clearOnEdit]="options.clearOnEdit == null ? defaultOpts.clearOnEdit : options.clearOnEdit"
              [clearInput]="options.clearInput == null ? defaultOpts.clearInput : options.clearInput"
              [ngClass]="{'hidden': !useIonInput}"
      >
      </ion-input>
      <ion-searchbar
              #searchbarElem
              (ionInput)="getItems()"
              (ionBlur)="handleBlur($event)"
              (tap)="showResultsFirst && getItems()"
              [(ngModel)]="keyword"
              [cancelButtonText]="options.cancelButtonText == null ? defaultOpts.cancelButtonText : options.cancelButtonText"
              [showCancelButton]="options.showCancelButton == null ? defaultOpts.showCancelButton : options.showCancelButton"
              [debounce]="options.debounce == null ? defaultOpts.debounce : options.debounce"
              [placeholder]="options.placeholder == null ? defaultOpts.placeholder : options.placeholder"
              [autocomplete]="options.autocomplete == null ? defaultOpts.autocomplete : options.autocomplete"
              [autocorrect]="options.autocorrect == null ? defaultOpts.autocorrect : options.autocorrect"
              [spellcheck]="options.spellcheck == null ? defaultOpts.spellcheck : options.spellcheck"
              [type]="options.type == null ? defaultOpts.type : options.type"
              [ngClass]="{'hidden': useIonInput}"
              (ionClear)="clearValue(true)"
              (keypress)="disableEnterKey($event)"
      >
      </ion-searchbar>
      <ion-spinner *ngIf="isBusy" name="crescent"></ion-spinner>
      <ng-template #defaultTemplate let-attrs="attrs">
          <span [innerHTML]='(attrs.labelAttribute ? attrs.data[attrs.labelAttribute] : attrs.data) | boldprefix:attrs.keyword'></span>
      </ng-template>
      <ul *ngIf="suggestions.length > 0 && showList">
          <li *ngFor="let suggestion of suggestions" (tap)="select(suggestion);$event.srcEvent.stopPropagation()">
              <ng-template
                      [ngTemplateOutlet]="template || defaultTemplate"
                      [ngTemplateOutletContext]="
                        {attrs:{ data: suggestion, keyword: keyword, labelAttribute: dataProvider.labelAttribute }}"></ng-template>
          </li>
      </ul>
      <p *ngIf="suggestions.length == 0 && showList && options.noItems">{{ options.noItems }}</p>
  