import { PropertyModel } from '@apto/models';
import { AppService, CacheService, PropertiesService } from '@apto/services';
import { LogsService } from '@apto/ionic-lib/services';
import { MappingStore } from './store';
var MappingCollection = /** @class */ /*@__PURE__*/ (function () {
    function MappingCollection(cacheService, store, propertiesService, logsService) {
        var _this = this;
        this.cacheService = cacheService;
        this.store = store;
        this.propertiesService = propertiesService;
        this.logsService = logsService;
        this.labelAttribute = 'name';
        this.serviceName = 'Mapping';
        AppService.onLogout.subscribe(function () {
            _this.cacheService.clear();
        }, this.logsService.error);
        this.propertiesService.onUpserted.subscribe(function (event) {
            _this.cacheService.add(_this.serviceName, event.payload._id, event.payload);
        }, this.logsService.error);
        this.propertiesService.onDeleted.subscribe(function (event) {
            event.payload.map(function (i) { return _this.cacheService.remove(_this.serviceName, i._id); });
        }, this.logsService.error);
    }
    MappingCollection.prototype.updateProperty = function (property) {
        return this.store.updateProperty(property)
            .then(function (result) {
            if (result.data.successes.length) {
                return true;
            }
            return false;
        });
    };
    MappingCollection.prototype.fetch = function (params) {
        var _this = this;
        return this.store.fetch(params)
            .then(function (result) {
            if (result.data && result.data.count > 0) {
                return {
                    total: result.data.total,
                    properties: _this.process(result.data.results)
                };
            }
            return {
                total: 0,
                properties: []
            };
        });
    };
    MappingCollection.prototype.process = function (result) {
        var _this = this;
        return result.map(function (x) {
            var item = new PropertyModel(_this.transformMappingToPropertyJson(x), 'transformFromMappingJson');
            _this.cacheService.add(_this.serviceName, item._id, item);
            return item;
        });
    };
    MappingCollection.prototype.transformMappingToPropertyJson = function (mappingJson) {
        var fieldFormat = function (value) { return value && Number(value).toString(); };
        var fieldsToNorm = [
            'landAcres',
            'landSquareFeet',
            'squareFootage',
            'stories',
            'parkingSpaces',
            'occupancy',
        ];
        var data = {
            _id: mappingJson.sf_id,
            buildingLocation: mappingJson.building_location,
            // category: mappingJson.?,
            class: mappingJson.class,
            defaultImageAttachmentId: mappingJson.image_attachment_id,
            description: mappingJson.description,
            landAcres: mappingJson.land_acres,
            landSquareFeet: mappingJson.land_sf,
            market: mappingJson.market,
            name: mappingJson.name,
            occupancy: mappingJson.occupancy,
            parcelNumber: mappingJson.apn || null,
            parkingSpaces: mappingJson.parking_spaces,
            parkingRatio: mappingJson.parking_ratio,
            postalCode: mappingJson.zip_code,
            primaryUse: mappingJson.primary_use,
            recordTypeId: mappingJson.record_type,
            saleDate: mappingJson.last_sale_date,
            salePrice: mappingJson.last_sale_price,
            squareFootage: mappingJson.square_footage,
            state: mappingJson.state,
            city: mappingJson.city,
            county: mappingJson.county,
            country: mappingJson.country,
            status: mappingJson.status,
            stories: mappingJson.number_of_stories,
            street: mappingJson.street_address,
            submarket: mappingJson.submarket,
            tenancy: mappingJson.tenancy,
            type: mappingJson.record_type ? this.recordTypeMapping[mappingJson.record_type] : null,
            units: mappingJson.units,
            yearBuilt: mappingJson.year_built,
            yearRenovated: mappingJson.year_renovated,
            zoning: mappingJson.zoning,
            latitude: mappingJson.latitude,
            longitude: mappingJson.longitude,
            addressSuggestions: mappingJson.address_suggestions,
        };
        fieldsToNorm.forEach(function (key) {
            data[key] = fieldFormat(data[key]);
        });
        return data;
    };
    return MappingCollection;
}());
export { MappingCollection };
