import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AlertController, NavParams, ToastController, ViewController } from 'ionic-angular';
import { CallListModel } from '@apto/models';
import { CallListsService, ContactGroupMembersService } from '@apto/services';
import { TOAST_DURATION } from '@apto/shared/constants';
import { BaseSlideModal } from '@apto/shared/modals';
import { EditCallListForm, PaginationNavigation } from '@apto/shared/components';
export var CallListModalMode = /*@__PURE__*/ (function (CallListModalMode) {
    CallListModalMode[CallListModalMode["LIST"] = 0] = "LIST";
    CallListModalMode[CallListModalMode["VIEW"] = 1] = "VIEW";
    CallListModalMode[CallListModalMode["EDIT"] = 2] = "EDIT";
    return CallListModalMode;
})({});
import './call-list-modal.scss';
import { Router } from '@angular/router';
var noop = function () { };
var ɵ0 = noop;
var CallListModal = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(CallListModal, _super);
    function CallListModal(callListsService, contactGroupMembersService, navParams, toastController, alertController, viewController, router) {
        var _this = _super.call(this, viewController, navParams) || this;
        _this.callListsService = callListsService;
        _this.contactGroupMembersService = contactGroupMembersService;
        _this.toastController = toastController;
        _this.alertController = alertController;
        _this.router = router;
        _this.addedContacts = [];
        _this.modalUrls = new Map([
            [CallListModalMode.VIEW, '/:callListId']
        ]);
        _this.Modes = CallListModalMode;
        _this.records = [];
        _this.selectedRecord = new CallListModel({ configJson: {}, type: CallListModel.TYPE.CONTACT_GROUP_LIST });
        _this.listUpdating = false;
        _this.subscriptions = [];
        _this.marketingStatus = _this.navParams.get('marketingStatus');
        _this.mode = _this.navParams.get('mode') || CallListModalMode.LIST;
        _this.records = _this.navParams.get('callLists') || [];
        if (_this.navParams.get('callList')) {
            _this.selectedRecord = _this.navParams.get('callList');
        }
        _this.addedContacts = _this.navParams.get('addedContacts') || [];
        _this.onSaveSuccess = _this.navParams.get('onSaveSuccess') || noop;
        return _this;
    }
    CallListModal.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions = [
            this.callListsService.onMembersAdded.subscribe(function (data) {
                return __awaiter(_this, void 0, void 0, function () {
                    var callListId, _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                callListId = this.editCallListForm.callList._id;
                                _a = this;
                                return [4 /*yield*/, this.callListsService.byId(callListId, true)];
                            case 1:
                                _a.selectedRecord = _b.sent();
                                this.editCallListForm.removedMembers = [];
                                this.editCallListForm.addedContacts = [];
                                this.editCallListForm.callList = this.selectedRecord;
                                this.editCallListForm.setupForm();
                                this.listUpdating = false;
                                return [2 /*return*/];
                        }
                    });
                });
            }),
        ];
    };
    CallListModal.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    CallListModal.prototype.goToContactDashboard = function (contact) {
        this.viewController.dismiss({
            selectedContact: contact
        });
        this.router.navigate(['/call-lists', this.selectedRecord._id, contact._id]);
    };
    CallListModal.prototype.viewContact = function (member) {
        if (member.callResult) {
            this.dismiss();
            this.router.navigate(['/contacts', member.contactId]);
        }
        else {
            this.goToContactDashboard(member.contact);
        }
    };
    CallListModal.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var formResult, alert_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.editCallListForm.isSaving = true;
                        return [4 /*yield*/, this.editCallListForm.save()];
                    case 1:
                        formResult = _a.sent();
                        this.editCallListForm.isSaving = false;
                        if (!(formResult && formResult.callList))
                            return [3 /*break*/, 6];
                        if (!!formResult.callList._id)
                            return [3 /*break*/, 3];
                        if (formResult.addedContacts.length === 0) {
                            this.showToast('Call list has no members!');
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, this.resumeSave(formResult)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 3:
                        if (!(formResult.callList.members.length +
                            formResult.addedContacts.length -
                            formResult.removedMembers.length <=
                            0))
                            return [3 /*break*/, 4];
                        alert_1 = this.createAlert('This call list contains no contacts and will be deleted.');
                        alert_1.onDidDismiss(function (_, role) {
                            return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!(role === 'OK'))
                                                return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.delete()];
                                        case 1:
                                            _a.sent();
                                            return [3 /*break*/, 3];
                                        case 2: return [2 /*return*/];
                                        case 3: return [2 /*return*/];
                                    }
                                });
                            });
                        });
                        alert_1.present();
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, this.resumeSave(formResult)];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    CallListModal.prototype.resumeSave = function (formResult) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var isNew, callListToUpsert, savedCallList_1, addedMembers, results, removedMemberIds, count, name_1, message;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.scrollToTop();
                        this.editCallListForm.isSaving = true;
                        isNew = !formResult.callList._id;
                        callListToUpsert = new CallListModel({});
                        Object.assign(callListToUpsert, formResult.callList);
                        if (!isNew)
                            return [3 /*break*/, 2];
                        callListToUpsert.contactIds = this.addedContacts.map(function (contact) { return contact._id; });
                        return [4 /*yield*/, this.callListsService.upsert(callListToUpsert)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 6];
                    case 2: return [4 /*yield*/, this.callListsService.upsert(callListToUpsert)];
                    case 3:
                        savedCallList_1 = _a.sent();
                        // set the batch info for this call list so that the UI knows that an update is in progress.
                        formResult.callList.batchInfo = {
                            id: null,
                            jobId: null,
                            totalContacts: (formResult.callList.members && formResult.callList.members.length) || 0,
                            processedContacts: 0
                        };
                        if (!(formResult.addedContacts.length > 0))
                            return [3 /*break*/, 5];
                        addedMembers = formResult.addedContacts.map(function (contact) {
                            return {
                                contactId: contact._id,
                                contactGroupId: savedCallList_1.contactGroupId
                            };
                        });
                        return [4 /*yield*/, this.callListsService.bulkInsertMembers({ models: addedMembers, callListId: savedCallList_1._id })];
                    case 4:
                        results = _a.sent();
                        formResult.callList.batchInfo = results.batchInfo;
                        if (!results.batchInfo) {
                            this.dismiss();
                            return [2 /*return*/];
                        }
                        this.listUpdating = true;
                        this.callListsService.startPolling();
                        _a.label = 5;
                    case 5:
                        if (formResult.removedMembers.length > 0) {
                            removedMemberIds = formResult.removedMembers.map(function (member) {
                                return member._id;
                            });
                            this.contactGroupMembersService.bulkDelete(removedMemberIds);
                            removedMemberIds.forEach(function (id) {
                                var i = _this.selectedRecord.members.length;
                                while (i--) {
                                    var member = _this.selectedRecord.members[i];
                                    if (id === member._id) {
                                        _this.selectedRecord.members.splice(i, 1);
                                    }
                                }
                            });
                            this.editCallListForm.removedMembers = [];
                            this.editCallListForm.addedContacts = [];
                            this.editCallListForm.callList = this.selectedRecord;
                            this.editCallListForm.setupForm();
                            count = removedMemberIds.length;
                            name_1 = savedCallList_1.name;
                            message = "Removed " + count + " contact" + (count > 1 ? 's' : '') + " from " + name_1 + ".";
                            this.showToast(message);
                        }
                        _a.label = 6;
                    case 6:
                        this.editCallListForm.isSaving = false;
                        this.onSaveSuccess(callListToUpsert);
                        this.dismiss();
                        return [2 /*return*/];
                }
            });
        });
    };
    CallListModal.prototype.delete = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.dismiss();
                this.showToast("Deleted " + this.selectedRecord.name + ".");
                this.callListsService.delete([String(this.selectedRecord._id)]);
                return [2 /*return*/];
            });
        });
    };
    CallListModal.prototype.showToast = function (message) {
        var toast = this.toastController.create({
            duration: TOAST_DURATION,
            message: message,
            showCloseButton: true
        });
        toast.present();
    };
    CallListModal.prototype.createAlert = function (message) {
        return this.alertController.create({
            title: 'Attention',
            subTitle: message,
            buttons: [
                { role: 'OK', text: 'OK' },
                { role: 'CANCEL', text: 'CANCEL' },
            ],
            cssClass: 'alert-massage-info',
        });
    };
    return CallListModal;
}(BaseSlideModal));
export { CallListModal };
export { ɵ0 };
